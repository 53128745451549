.link-icon {
  color: #f5f5f5;
  font-size: 48px;
  &:hover {
    color: $white;
  }
}

.menu {
  z-index: 100;
  @apply transition-all duration-500;
  @apply flex flex-col items-center justify-start absolute pt-4;
}

.nav-link {
  @apply text-white transform hover:scale-105 transition-all duration-500 m-12;
  @apply flex justify-center relative;
  &::after {
    content: '';
    width: 0;
    position: absolute;
    @apply bg-white;
    bottom: -5px;
    height: 3px;
    @apply transition-all duration-500;
  }
  &:hover {
    &::after {
      width: 100%;
    }
  }
  &__home {
    transform: translateX(-50vw) translateY(-50vh);
  }
  &__websites {
    transform: translateX(50vw) translateY(-50vh);
  }
  &__resume {
    transform: translateX(-50vw) translateY(50vh);
  }
  &__repos {
    transform: translateX(50vw) translateY(50vh);
  }
  &__new-website {
    @apply col-span-1;
    transform: translateY(50vh);
  }
  &__home.active,
  &__websites.active,
  &__resume.active,
  &__repos.active,
  &__new-website.active {
    transform: translateX(0) translateY(0);
  }
}

.burger .burger-lines,
.burger .burger-lines:after,
.burger .burger-lines:before {
  background-color: $black;
}

@media only screen and (max-width: 640px) {
  .burger .burger-lines,
  .burger .burger-lines:after,
  .burger .burger-lines:before {
    background-color: $white;
  }
}

.burger.open .burger-lines,
.burger.open .burger-lines:after,
.burger.open .burger-lines:before {
  background-color: $white;
}

.logo {
  width: 30px;
}
