.cv {
  h1,
  h2,
  h3 {
    @apply tracking-widest;
  }
  .trix-content {
    color: inherit !important;
  }
}
