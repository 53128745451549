h1 {
  @apply text-4xl;
}

h2 {
  @apply text-3xl;
}

h3 {
  @apply text-2xl;
}

h4 {
  @apply text-xl;
}

h5 {
  @apply text-lg;
}

h6 {
  @apply text-base font-bold;
}

a {
  @apply text-blue-600 no-underline;
}

body {
  background: linear-gradient(to left, $white 50%, #111827 50%);
  @media only screen and (max-width: 640px) {
    background: #111827;
  }
  @apply text-white;
  min-height: calc(var(--vh, 1vh) * 100);
}

input.form-input {
  color: black !important;
}
