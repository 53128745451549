.form-input,
trix-editor {
  @apply shadow appearance-none relative block w-full px-3 py-2;
  @apply border border-gray-300;
  @apply focus:outline-none focus:ring-gray-300 focus:border-gray-300 focus:z-10;
  @apply placeholder-gray-500 sm:text-sm;
}

.checkbox {
  @apply h-4 w-4 text-gray-100 focus:ring-gray-100 border-gray-300 rounded;
  &:checked {
    @apply bg-black;
  }
}
