// Define variables for your color scheme

// For example:
$red: #7f1d1d;
$blue: #1e3a8a;
$yellow: #fbbf24;
$orange: #78350f;
$green: #064e3b;
$gray: #111827;
$light-gray: #9ca3af;
$white: #ffffff;
$black: #000000;
