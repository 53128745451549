.website-card {
  background-size: cover;
  background-position: center;
  @apply duration-700 transition-all;
  &--layover {
    @apply h-full w-full inset-0;
    @apply opacity-0 hover:opacity-100 bg-black bg-opacity-0 hover:bg-opacity-60 text-white;
    @apply duration-700 transition-all;
    @apply flex flex-col items-center justify-center;
    @apply p-4;
  }

  &--phone {
    @apply text-white;
    @apply transition-all duration-700;
    @apply flex flex-col;
    @apply p-4;
  }
}

.website-navigation {
  background: rgba(0, 0, 0, 0.4);
  @apply rounded-md shadow;
}

.websites {
  @apply transition-all duration-700 absolute w-full h-full overflow-hidden grid top-0;
  right: 0;
  &.next {
    right: -100%;

    .website-card {
      transform: scale(0.8);
    }
  }
  &.previous {
    right: 100%;
    .website-card {
      transform: scale(0.8);
    }
  }
}

.row-span-2 {
  grid-row: span 2 / span 2;
}
