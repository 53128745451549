.cube-wrapper {
  width: 20px;
  height: 20px;
  perspective: 500px;
  @apply cursor-pointer;
  &.active {
    box-shadow: 0 0 15px $white;
  }
}

@keyframes turn {
  from {
    transform: rotate3d(0, 0, 0, 0);
  }
  to {
    transform: rotate3d(1, 1, 0, 360deg);
  }
}

.cube {
  position: relative;
  width: 20px;
  height: 20px;
  transform-style: preserve-3d;
  animation: turn 5s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .cube {
    animation: none;
    transform: rotate3d(1, 1, 0, 45deg);
  }
}

.face {
  width: 20px;
  height: 20px;
  background: $white;
  border: 2px solid $black;
  position: absolute;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, sans-serif;
  font-size: 2rem;
  &.active {
    opacity: 1;
  }
}

.front {
  transform: translateZ(10px);
}

.back {
  transform: translateZ(-10px) rotateY(180deg);
}

.left {
  transform: translateX(-10px) rotateY(-90deg);
}

.right {
  transform: translateX(10px) rotateY(90deg);
}

.top {
  transform: translateY(-10px) rotateX(90deg);
}

.bottom {
  transform: translateY(10px) rotateX(-90deg);
}
