.container {
  @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:py-5;
}

.flex-centered {
  @apply w-full h-full flex justify-center items-center;
}

.min-h-wrapper {
  min-height: calc(var(--vh, 1vh) * 100 - 100px);
}

.max-height-70 {
  max-height: 70vh;
}

.shrink-down {
  animation: rotate 1s linear 1;
  &-no-animation {
    animation-duration: 0s !important;
  }
}

@keyframes rotate {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(0) rotate(1800deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

.card {
  @apply border border-white p-3 md:col-start-2 md:col-end-11 lg:col-start-4 lg:col-end-10 col-span-12 md:col-span-8 lg:col-span-6 rounded shadow-lg;
}

.font-75 {
  font-size: 75% !important;
}

.font-80 {
  font-size: 0.8rem !important;
}

.font-8p {
  font-size: 8px !important;
}

.font-10p {
  font-size: 10px !important;
}

.font-12p {
  font-size: 12px !important;
}

.bg-primary {
  background-color: $gray;
}
