.ap-suggestion {
  display: flex;
  align-items: center;
}

.ap-footer {
  display: flex;
  align-items: center;
  justify-content: end;
}

.ap-footer-osm {
  @apply flex items-center ml-2;
}

.ap-footer-algolia {
  @apply mr-2;
}

.ap-input-icon svg {
  z-index: 1000;
}
