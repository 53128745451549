@import 'trix/dist/trix';

.trix-content {
  @apply text-black;
  .attachment-gallery {
    > action-text-attachment,
    > .attachment {
      flex: 1 0 100%;
      padding: 0 0.5em;
      max-width: 100%;
    }

    &.attachment-gallery--2,
    &.attachment-gallery--4 {
      > action-text-attachment,
      > .attachment {
        flex-basis: 50%;
        max-width: 100%;
      }
    }
  }

  action-text-attachment {
    .attachment {
      padding: 0 !important;
      max-width: 100% !important;
    }
  }
}

.trix-button-group {
  @apply bg-white;
}
